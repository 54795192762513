<template>
  <a-config-provider :locale="zh_CN">
    <router-view v-if="isRouterAlive" :key="$route.path + $route.query.t"/>
  </a-config-provider>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");
export default {
  provide() {
    return {
      reload: this.reload,

    }
  },

  data() {
    return {
      zh_CN,
      language: localStorage.language,
      isRouterAlive: true,
      webinfos: "",
      Links: "",
      Cooperation: "",
    };
  },
  created() {
    // 获取头部菜单内容
    // this.getCategory();
    // console.log("localStorage", this.language)
    if (this.language === undefined) {

      const str = "cn";
      const str2 = "";
      localStorage.setItem('language', str);
      localStorage.setItem('login_name', str2);
      this.language = localStorage.getItem('language')
    }
    console.log("localStorage", localStorage.getItem('language'))
    Promise.all([this.getwebinfo("1"),this.getwebinfo("3"),this.getwebinfo("4")]).then(() => {
      this.getindex()
      this.loading = false;
    }).catch((error) => {
      console.log(error)
    })
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
      console.log("开始刷新");
    },
    async getwebinfo(type) {
      this.loading = true;
      let result = await this.$get("/api/customer/systemSet/list", {
        params: {
          type: type,
          // language: this.language,
        }
      });
      // console.log("开始刷新webinfo",result.data);
      if (result.data.status === true) {
        // console.log("开始刷新webinfo",result.data);
        // console.log("开始刷新webinfo",type);
        if(type === "1"){
          console.log("开始刷新-1",result.data);
          sessionStorage.removeItem('webinfos');
          sessionStorage.setItem('webinfos', JSON.stringify(result.data.data[0]));
          this.webinfos = JSON.parse(sessionStorage.getItem('webinfos'))
          console.log("webinfos-new", this.webinfos)
        } if(type === "3"){
          console.log("开始刷新-3",result.data);
          sessionStorage.removeItem('Links');
          sessionStorage.setItem('Links', JSON.stringify(result.data.data));
          this.Links = JSON.parse(sessionStorage.getItem('Links'))
          console.log("Links-new", this.Links)
        } if(type ==="4"){
          console.log("开始刷新-4",result.data);
          sessionStorage.removeItem('Cooperation');
          sessionStorage.setItem('Cooperation', JSON.stringify(result.data.data));
          this.Cooperation = JSON.parse(sessionStorage.getItem('Cooperation'))
          console.log("Cooperation-new", this.Cooperation)
        }
        this.language = localStorage.getItem('language')
        // console.log("日历Data" ,JSON.parse(this.calendarData));
        this.loading = false;
      }

      this.loading = false;
    },
    async getindex() {
      // console.log("applicationgetindex", this.language)
      this.loading = true;
      this.navbar = [];

      let now = Date.now();
      let result = await this.$get("/api/customer/articleCategory/list", {
        params: {
          // token: Storage.token,
          language: this.language,
          is_show: "1",
          t: now,
        }
      });
      if (result.status === true) {
        // console.log("applicationgetindex", result)
        this.navbar = result.data

        sessionStorage.removeItem('navbar');
        sessionStorage.setItem('navbar', JSON.stringify(result.data));

        // console.log("navbar", JSON.parse(sessionStorage.getItem('navbar')))
        let selected = []
        selected.push(result.data[0].id)
        sessionStorage.setItem('navbarselected', JSON.stringify(selected));
        this.$forceUpdate()
        // this.navbarselected= result.data[0].id
        this.loading = false;
      }

      this.loading = false;
    },
  },
  components: {}
};
// require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
// Use only when you are using Webpack
</script>
<style>
.green {
  color: #00AA00
}

.red {
  color: #ff4d4f
}

body {
  background-color: #fefefe !important;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', 'WenQuanYi Micro Hei', sans-serif;
  /*overflow-y: scroll;*/
  overflow-x: hidden;
}

.ant-menu-submenu-popup {
  border-radius: 18px !important;
  background-color: unset !important;
  padding-top: 20px !important
}

.ant-menu {
  border-radius: 18px !important;
  background-color: #fff !important;
  margin-top: 20px;
}
</style>
