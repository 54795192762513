export default {
    data: {
        debug: true,
        precision:1000,

        server_url: "https://api.psychomotricite.org/",
        server_url1: "https://www.psychomotricite.org/",
        // server_url: "https://isrpapi.6cd.com.cn",
        // map_server_url: "https://apis.map.qq.com",
        pay:{
            vip:50,
            tolecturer:50,
        },
        dictionary:{
            cn: {
                reg:"注册",
                login:"登录",
                other:"其他",
                EventsCalendar:"活动⽇历",
                ScientificArticles:"投稿论⽂",
                Announcements:"会员公告",
                Benefits:"会员权益",
                Subscribe:"订阅",
                MemberList:"会员名单",
                Partner:"合作伙伴",
                Subscribetoournewsletter:"订阅我们的邮件",
                Readmore:"了解更多",
                Traineecertificatequery:"培训者证书查询",
                Trainercertificatequery:"讲师证书查询",
                Inquiry:"查询",
                Membership:"加入会员",
                Calendar:"活动日历",
                DomesticNews:"国内新闻",
                InternationalNews:"国际新闻",
                Professors:"讲师风采",
                Thesis:"投稿论文",
                Onlinecourses:"线上学堂",
                Lastupdate:"最后更新时间",
                User:"网站用户",
                Membre:"会员",
                Trainee:"学员",
            },
            en:  {
                reg:"Sign Up",
                login:"Log In",
                other:"Other",
                EventsCalendar:"Events Calendar",
                ScientificArticles:"Scientific Articles",
                Announcements:"Announcements",
                Benefits:"Benefits",
                Subscribe:"Subscribe",
                MemberList:"Member List",
                Partner:"Partner",
                Subscribetoournewsletter:"Subscribe to our newsletter",
                Readmore:"Read more",
                Traineecertificatequery:"Trainee certificate query",
                Trainercertificatequery:"Trainer certificate query",
                Inquiry:"Inquiry",
                Membership:"Membership",
                Calendar:"Calendar",
                DomesticNews:"Domestic News",
                InternationalNews:"International News",
                Professors:"Professors",
                Thesis:"Thesis",
                Onlinecourses:"Online courses",
                Lastupdate:"Last update",
                User:"User",
                Membre:"Membre",
                Trainee:"Trainee",
            },
            fr:  {
                reg:"Inscrivez-vous",
                login:"Connexion",
                other:"Autres",
                EventsCalendar:"Calendrier d’évènements",
                ScientificArticles:"Articles scientifiques",
                Announcements:"Annonces",
                Benefits:"Droits",
                Subscribe:"Je m’abonne",
                MemberList:"Liste des membres",
                Partner:"Partenaires",
                Subscribetoournewsletter:"Abonnez-vous à la newsletter",
                Readmore:"Plus d’informations",
                Traineecertificatequery:"Vérification du certificat de formation continue",
                Trainercertificatequery:" Vérification du certificat d’ instructeur",
                Inquiry:"Vérificaton",
                Membership:"Membres",
                Calendar:"Calendrier des événements",
                DomesticNews:"Infos Nationales",
                InternationalNews:"Infos Internationales",
                Professors:"Professeurs",
                Thesis:"Thèse",
                Onlinecourses:"Cours en ligne",
                Lastupdate:"Dernière mise à jour",
                User:"Utilisateur",
                Membre:"Membre",
                Trainee:"Trainee",
            },
        },

    }
}
