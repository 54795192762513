import Vue from 'vue'
import VueRouter from 'vue-router'
// import Layout from '../../src/layout/index.vue' // 主框架路径
import refresh from '../views/refresh.vue'

Vue.use(VueRouter)
// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}

const routes = [{
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
},
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue'),
    }, {
        path: '/reg',
        name: 'reg',
        component: () => import('../views/reg.vue'),
    }, {
        path: '/verify',
        name: 'verify',
        component: () => import('../views/verify.vue'),
    }, {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('../views/reset-password.vue'),
    }, {
        path: '/myinfo',
        name: 'myinfo',
        component: () => import('../views/myinfo.vue'),
    }, {
        path: '/category_list',
        name: 'category_list',
        component: () => import('../views/category_list.vue'),
    }, {
        path: '/article',
        name: 'article',
        component: () => import('../views/article.vue'),
    }, {
        path: '/PlayerVideo',
        name: 'PlayerVideo',
        component: () => import('../views/PlayerVideo.vue'),
    },{
        path: '/payvip',
        name: 'payvip',
        component: () => import('../views/payvip.vue'),
    },{
        path: '/articletextonly',
        name: 'articletextonly',
        component: () => import('../views/articletextonly.vue'),
    },{
        path: '/searchresults',
        name: 'searchresults',
        component: () => import('../views/searchresults.vue'),
    },{
        path: '/Certificateinfo',
        name: 'Certificateinfo',
        component: () => import('../views/Certificateinfo.vue'),
    },
    {
        path: '/refresh',
        name: 'refresh',
        component: refresh
    },
]

const router = new VueRouter({
    routes,
    // mode: 'hash'
    mode: 'history',
})

export default router
